<template>
  <b-card class="mb-1 p-1" no-body>
    <b-modal
        centered
        title="جزییات سفارش"
        v-model="orderModal"
        hide-footer
        size="sm"
    >
      <b-form-group label="تنظیمات مقدار">
        <b-input-group class="mb-1">
          <b-input-group-prepend is-text>
            سفارش گذاری از
          </b-input-group-prepend>
          <b-form-input dir="ltr" v-model="data.fromAmount"
                        @change="data.fromAmount = $S2N($event || 0).toFixed(data.decimal)"/>
          <b-input-group-append is-text>
            {{ $coinLabel[$M2RC(data.marketType)] }}
          </b-input-group-append>
        </b-input-group>
        <b-input-group class="mb-1">
          <b-input-group-prepend is-text>
            سفارش گذاری تا
          </b-input-group-prepend>
          <b-form-input dir="ltr" v-model="data.toAmount"
                        @change="data.toAmount = $S2N($event || 0).toFixed(data.decimal)"/>
          <b-input-group-append is-text>
            {{ $coinLabel[$M2RC(data.marketType)] }}
          </b-input-group-append>
        </b-input-group>
        <b-input-group>
          <b-input-group-prepend is-text>
            دقت مقدار سفارش
          </b-input-group-prepend>
          <b-form-spinbutton min="0" max="8" v-model="data.decimal" @change="fixAmount"/>
          <b-input-group-append is-text>
            رقم اعشار
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="تنظیمات قیمت">
        <p class="text-danger font-small-3" v-if="danger && FromPrice && ToPrice">
          {{danger}}
        </p>
        <b-input-group class="mb-1">
          <b-input-group-prepend is-text>
            از
          </b-input-group-prepend>
          <b-form-input dir="ltr" v-model="data[fromPercent ? 'fromPricePercent' : 'fromPriceAmount']"
                        @change="
                        data.fromPriceAmount = Math.abs($A2N(data.fromPriceAmount || 0)).toFixed(data.precision).toLocaleString('en');
                        data.fromPricePercent = Math.abs($A2N(data.fromPricePercent || 0));"/>
          <b-input-group-append>
            <b-button-group>
              <b-button :variant="'outline-' + fromDanger" v-tooltip="fromPercent ? '⟲ براساس ارز':'⟲ بر اساس درصد'"
                        @click="fromPercent = !fromPercent" class="px-1">
                {{ fromPercent ? 'درصد' : $coinLabel[$M2SC(data.marketType)] }}
              </b-button>
              <b-button :variant="'outline-' + fromDanger" v-tooltip="fromNegative ? '⟲ بیشتر از':'⟲ کمتر از'"
                        @click="fromNegative = !fromNegative" class="px-1">
                {{ fromNegative ? 'کمتر از' : 'بیشتر از' }}
              </b-button>
              <b-button variant="outline-secondary" class="px-1">
                قیمت جهانی
              </b-button>
            </b-button-group>
          </b-input-group-append>
        </b-input-group>
        <b-input-group class="mb-1">
          <b-input-group-prepend is-text>
            تا
          </b-input-group-prepend>
          <b-form-input dir="ltr" v-model="data[toPercent ? 'toPricePercent' : 'toPriceAmount']"
                        @change="
                        data.toPriceAmount = Math.abs($A2N(data.toPriceAmount || 0)).toFixed(data.precision).toLocaleString('en');
                        data.toPricePercent = Math.abs($A2N(data.toPricePercent || 0));"/>
          <b-input-group-append>
            <b-button-group>
              <b-button :variant="'outline-' + toDanger" v-tooltip="toPercent ? '⟲ براساس ارز':'⟲ بر اساس درصد'"
                        @click="toPercent = !toPercent" class="px-1">
                {{ toPercent ? 'درصد' : $coinLabel[$M2SC(data.marketType)] }}
              </b-button>
              <b-button :variant="'outline-' + toDanger" v-tooltip="toNegative ? '⟲ بیشتر از':'⟲ کمتر از'"
                        @click="toNegative = !toNegative" class="px-1">
                {{ toNegative ? 'کمتر از' : 'بیشتر از' }}
              </b-button>
              <b-button variant="outline-secondary" class="px-1">
                قیمت جهانی
              </b-button>
            </b-button-group>
          </b-input-group-append>
        </b-input-group>
        <b-input-group>
          <b-input-group-prepend is-text>
            دقت قیمت سفارش
          </b-input-group-prepend>
          <b-form-spinbutton min="0" max="8" v-model="data.precision" @change="fixBase"/>
          <b-input-group-append is-text>
            رقم اعشار
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="تاریخ انقضای سفارش">
        <b-input-group class="mb-1">
          <b-input-group-prepend is-text>
            مقدار
          </b-input-group-prepend>
          <b-form-spinbutton min="0" :max="24*60" wrap step="0.5" :value="data.exp/60" @input="e => data.exp = e*60"/>
          <b-input-group-append is-text>
            دقیقه
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

    </b-modal>
    <b-form @submit.prevent="sendData">
      <b-overlay :show="loading">
        <b-row>
          <b-col cols="12" md="6">
            <b-row>
              <!-- Field: Name -->
              <b-col
                  cols="12"
                  sm="12"
                  lg="6"
              >
                <b-form-group
                    label="نام کاربری"
                    label-for="roleName"
                >
                  <b-form-input
                      v-if="data.id"
                      id="roleName"
                      v-model="data.name"
                      :clearable="true"
                      disabled
                  />
                  <v-select
                      v-else
                      :options="supervisors"
                      :value="data.name"
                      label="email"
                      :disabled="spinner"
                      @input="updateUser"
                  >
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- Field: MarketType -->
              <b-col
                  cols="12"
                  sm="12"
                  lg="6"
              >
                <b-form-group
                    :label="`بازار (${$coinLabel[$M2RC(data.marketType)]} - ${$coinLabel[$M2SC(data.marketType)]})`"
                    label-for="en-name"
                >
                  <b-input-group>
                    <b-input-group-prepend style="flex-basis: 50%">
                      <v-select
                          v-model="marketType.from"
                          dir="rtl"
                          :multiple="false"
                          :options="filteredMarket"
                          :clearable="false"
                          :filterable="false"
                          :reduce="val => val.destinationCoin"
                          @input="setMarketType"
                          @search="marketSearch = $event"
                          style="width: 100%"
                      >
                        <template #selected-option="{ destinationCoin }">
                    <span class="font-small-3">
                      {{ $coinLabel[destinationCoin] }}
                    </span>
                        </template>
                        <template #option="{destinationCoin}">
                    <span class="d-flex align-items-center">
                      <b-avatar
                          size="20"
                          class="mr-1"
                          :src="require(`@/assets/images/Coins/${destinationCoin}.png`)"
                          :variant="`light-info`"
                      />
                      {{ $coinLabel[destinationCoin] }}
                    </span>
                        </template>
                      </v-select>
                    </b-input-group-prepend>
                    <b-input-group-append style="flex-basis: 50%">
                      <v-select
                          v-model="marketType.to"
                          dir="rtl"
                          :multiple="false"
                          :options="['TOMAN','TETHER']"
                          :clearable="false"
                          :reduce="val => val"
                          @input="setMarketType"
                          style="width: 100%"
                      >
                        <template #selected-option="{label}">
                    <span class="font-small-3">
                      {{ $coinLabel[label] }}
                    </span>
                        </template>
                        <template #option="{label}">
                    <span class="d-flex align-items-center">
                      <b-avatar
                          size="20"
                          class="mr-1"
                          :src="require(`@/assets/images/Coins/${label}.png`)"
                          :variant="`light-info`"
                      />
                      {{ $coinLabel[label] || label }}
                    </span>
                        </template>
                      </v-select>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Field: OrderType -->
              <b-col
                  cols="12"
                  sm="12"
                  lg="6"
              >
                <b-form-group
                    label="نوع سفارش"
                    label-for="status"
                >
                  <b-button-group class="w-100">
                    <b-button
                        @click.prevent="data.orderType =  'BUY';fromNegative=true;toNegative=true;$forceUpdate();"
                        :variant="(data.orderType === 'BUY'?'':'outline-') + 'success'"
                    >
                      خرید
                    </b-button>
                    <b-button
                        @click.prevent="data.orderType =  'SELL';fromNegative=false;toNegative=false;$forceUpdate();"
                        :variant="(data.orderType === 'SELL'?'':'outline-') + 'danger'"
                    >
                      فروش
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>

              <!-- Field: Period -->
              <b-col
                  cols="12"
                  sm="12"
                  lg="6"
              >
                <b-form-group
                    label="دوره زمانی سفارش"
                    label-for="period"
                    @input="$test"
                >
                  <b-input-group>
                    <b-form-spinbutton wrap min="1" max="1440" :value="data.period/60"
                                       @input="e => data.period = e*60"/>
                    <b-input-group-append style="flex-basis: 50%">
                      <b-button variant="outline-primary" class="w-100">
                        دقیقه
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Field: DoOrder -->
              <b-col
                  cols="6"
                  sm="6"
                  lg="3"
              >
                <b-form-group
                    label="انجام سفارش های بازار"
                    label-for="status"
                >
                  <b-button-group class="w-100">
                    <b-button
                        @click.prevent="data.doOrder = true;$forceUpdate();"
                        :variant="(data.doOrder?'':'outline-') + 'success'"
                    >
                      بله
                    </b-button>
                    <b-button
                        @click.prevent="data.doOrder = false;$forceUpdate();"
                        :variant="(!data.doOrder?'':'outline-') + 'danger'"
                    >
                      خیر
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>

              <!-- Field: DoBetterPrice -->
              <b-col
                  cols="6"
                  sm="6"
                  lg="3"
                  class="px-10"
              >
                <b-form-group
                    label="انجام قیمت های بهتر"
                    label-for="status"
                >
                  <b-button-group class="w-100">
                    <b-button
                        :disabled="!data.doOrder"
                        @click.prevent="data.doBetterPrice = true;$forceUpdate();"
                        :variant="(data.doBetterPrice?'':'outline-') + 'success'"
                    >
                      بله
                    </b-button>
                    <b-button
                        :disabled="!data.doOrder"
                        @click.prevent="data.doBetterPrice = false;$forceUpdate();"
                        :variant="(!data.doBetterPrice?'':'outline-') + 'danger'"
                    >
                      خیر
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>

              <!-- Field: StopInMax -->
              <b-col
                  cols="6"
                  sm="6"
                  lg="3"
              >
                <b-form-group
                    label="توقف در حداکثر"
                    label-for="status"
                >
                  <b-button-group class="w-100">
                    <b-button
                        @click.prevent="data.stopInMax = true;$forceUpdate();"
                        :variant="(data.stopInMax?'':'outline-') + 'success'"
                    >
                      بله
                    </b-button>
                    <b-button
                        @click.prevent="data.stopInMax = false;$forceUpdate();"
                        :variant="(!data.stopInMax?'':'outline-') + 'danger'"
                    >
                      خیر
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>

              <!-- Field: Max -->
              <b-col
                  cols="6"
                  sm="6"
                  lg="3"
              >
                <b-form-group
                    label="حداکثر سفارش فعال"
                    label-for="status"
                >
                  <b-form-spinbutton
                      wrap
                      min="0"
                      max="100"
                      id="max"
                      v-model="data.max"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="6">
            <b-row>
              <b-col cols="12">
                بازارگردان با نام کاربری
                "{{ data.name }}"
                هر "{{ data.period / 60 }}" دقیقه یکبار در بازار
                "{{ $coinLabel[$M2RC(data.marketType)] }}/{{ $coinLabel[$M2SC(data.marketType)] }}"
                با جزییات زیر سفارش
                "{{ data.orderType === 'BUY' ? 'خرید' : 'فروش' }}"
                قرار می دهد.
                <br>
                جزییات سفارش : مقدار
                "{{ data.fromAmount }}"
                الی
                "{{ data.toAmount }}"
                بیتکوین در
                <template v-if="$A2N(FromPrice) || $A2N(ToPrice)">
                  بازه قیمتی
                </template>
                <template v-if="$A2N(FromPrice)">
                  "{{ data.fromPercent ? data.fromPricePercent : data.fromPriceAmount }}"
                  {{ data.fromPercent ? 'درصد' : $coinLabel[$M2SC(data.marketType)] }}
                  "{{ fromNegative ? 'ارزانتر' : 'گرانتر' }} از"
                </template>
                <template v-if="$A2N(FromPrice) || $A2N(ToPrice)">
                  قیمت جهانی
                  الی
                </template>
                <template v-if="$A2N(ToPrice)">
                  "{{ data.toPercent ? data.toPricePercent : data.toPriceAmount }}"
                  {{ data.toPercent ? 'درصد' : $coinLabel[$M2SC(data.marketType)] }}
                  "{{ toNegative ? 'ارزانتر' : 'گرانتر' }} از"
                </template>
                قیمت جهانی
                <template v-if="data.exp">
                  و با تاریخ انقضای
                  "{{ data.exp / 60 }}"
                  دقیقه بعد از ایجاد سفارش
                </template>
                <template v-else>
                  و بدون تاریخ انقضا
                </template>
                <br>
                <component :is="data.doOrder ? 'span':'s'">
                  در این دوره زمانی بهترین سفارش کاربران دیگر که در این بازه قیمتی
                  <template v-if="data.doBetterPrice">
                    "و بهتر از آن"
                  </template>
                  باشد توسط ربات به اندازه بازه مقداری تنظیم شده انجام میگیرد.
                </component>
                <br>
                <component :is="data.max ? 'span':'s'">
                  سفارش گذاری ربات تا ایجاد
                  "{{ data.max }}"
                  سفارش فعال ادامه می یابد و پس از آن
                  <template v-if="data.stopInMax">
                    سفارش گذاری متوقف شده و زمانی که حداقل یکی از سفارش ها به دلایلی ( لغو دستی سفارش ، تکمیل سفارش توسط
                    دیگر کاربران و منقضی شدن سفارش ) غیر فعال شود ربات به کار خود ادامه می دهد.
                    <br>
                    (*توجه نمایید که در این حالت وضعیت ربات در حال فعال باقی می ماند و در صورت فعال بودن انجام سفارش های
                    بازار این عمل متوقف نمی شود.)
                  </template>
                  <template v-else>
                    قدیمی ترین سفارش شما لغو شده سپس سفارش جدید گذاشته می شود.
                  </template>
                </component>
              </b-col>
              <!-- Field: OrderDetails -->
              <b-col
                  cols="6"
                  class="py-1 d-flex justify-content-end align-items-end px-10"
              >
                <b-button variant="outline-primary" class="w-100 px-0" @click.prevent="orderModal=true">
                  <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-50"
                  />
                  <span class="align-middle font-small-3">
              جزییات سفارش گذاری
            </span>
                </b-button>
              </b-col>

              <!-- Field: AddBot -->
              <b-col
                  cols="6"
                  class="py-1 d-flex justify-content-end align-items-end px-10"
              >
                <b-button type="submit" :disabled="!enabled" :variant="'outline-' + (job.id ? 'warning' : 'info')"
                          class="w-100">
                  <feather-icon
                      :icon="job.id ? 'EditIcon' : 'PlusIcon'"
                      class="mr-50"
                  />
                  <span class="align-middle">
              {{ job.id ? 'اصلاح ربات' : 'افزودن ربات' }}
            </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BModal,
  BOverlay,
  // BCardHeader,
  // BBadge,
  BFormSpinbutton,
  BButton,
  BButtonGroup,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  // BDropdown,ya
  // BDropdownItem,
  BAvatar,
  BRow,
  BCol,
} from "bootstrap-vue";
import vSelect from "vue-select"
import '@core/scss/vue/libs/vue-select.scss'

import {statusLabel, statusVariant} from "@/libs/methods";

export default {
  name: "CreateBot",
  props: ['job'],
  components: {
    BCard,
    BModal,
    BFormSpinbutton,
    BOverlay,
    // BCardHeader,
    // BBadge,
    BButton,
    BButtonGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    // BDropdown,
    // BDropdownItem,
    BAvatar,
    BRow,
    BCol,
    vSelect,
  },
  data() {
    return {
      data: this.job,
      coins: [],
      supervisors: [],
      marketType: {
        from: 'BITCOIN',
        to: 'TOMAN'
      },
      fromNegative: true,
      toNegative: true,
      fromPercent: false,
      toPercent: false,
      marketSearch: '',
      orderModal: false,
      periodTime: 'S',
      loading: false,
      spinner: false,
    }
  },
  computed: {
    danger() {
      let text = []
      let index = 3
      const fromUnit = this.fromPercent ? 'درصد' : this.$coinLabel[this.$M2SC(this.data.marketType)]
      const toUnit = this.toPercent ? 'درصد' : this.$coinLabel[this.$M2SC(this.data.marketType)]
      if (this.data.orderType === 'BUY' && (!this.fromNegative || !this.toNegative)) {
        text[0] = 'به مقدار'
        if (!this.fromNegative && !this.toNegative) {
          if (this.toPercent === this.fromPercent) {
            text[1] = this.$toLocal(Math.max(this.$A2N(this.FromPrice), this.$A2N(this.ToPrice)))
            text[2] = fromUnit
          } else {
            text[1] = this.FromPrice
            text[2] = fromUnit
            text[3] = 'و یا'
            text[4] = this.ToPrice
            text[5] = toUnit
            index = 6
          }
        } else if (!this.fromNegative) {
          text[1] = this.FromPrice
          text[2] = fromUnit
        } else {
          text[1] = this.ToPrice
          text[2] = toUnit
        }
        text[index] = 'به ازای هر واحد'
        text[index+1] = this.$coinLabel[this.$M2RC(this.data.marketType)]
        text[index+2] = 'احتمال ضرر دارید'
      }else if (this.data.orderType === 'SELL' && (this.fromNegative || this.toNegative)) {
        text[0] = 'به مقدار'
        if (this.fromNegative && this.toNegative) {
          if (this.toPercent === this.fromPercent) {
            text[1] = this.$toLocal(Math.max(this.$A2N(this.FromPrice), this.$A2N(this.ToPrice)))
            text[2] = fromUnit
          } else {
            text[1] = this.FromPrice
            text[2] = fromUnit
            text[3] = 'و یا'
            text[4] = this.ToPrice
            text[5] = toUnit
            index = 6
          }
        } else if (this.fromNegative) {
          text[1] = this.FromPrice
          text[2] = fromUnit
        } else {
          text[1] = this.ToPrice
          text[2] = toUnit
        }
        text[index] = 'به ازای هر واحد'
        text[index+1] = this.$coinLabel[this.$M2RC(this.data.marketType)]
        text[index+2] = 'احتمال ضرر دارید.'
      }
      return text?.length ? text.join(' ') : false
    },
    fromDanger(){
      const value = this.fromPercent ? 5 : this.$M2SC(this.data.marketType) === 'TETHER' ? 100 : 200000
      if((this.data.orderType === 'BUY' && !this.fromNegative) || (this.data.orderType === 'SELL' && this.fromNegative)){
        return this.$A2N(this.FromPrice) > value ? 'danger' : this.$A2N(this.FromPrice) > 0 ? 'warning' : 'primary'
      }else{
        return 'primary'
      }
    },
    toDanger(){
      const value = this.toPercent ? 5 : this.$M2SC(this.data.marketType) === 'TETHER' ? 100 : 200000
      if((this.data.orderType === 'BUY' && !this.toNegative) || (this.data.orderType === 'SELL' && this.toNegative)){
        return this.$A2N(this.ToPrice) > value ? 'danger' : this.$A2N(this.ToPrice) > 0 ? 'warning' : 'primary'
      }else{
        return 'primary'
      }
    },
    enabled() {
      console.log(this.data.name
          , this.data.token
          , (this.data.fromAmount + '')
          , (this.data.toAmount + '')
          , (this.$S2N(this.data.toAmount) >= this.$S2N(this.data.fromAmount))
          , (this.FromPrice + '')
          , (this.ToPrice + ''))
      return !!(this.data.name
          && this.data.token
          && (this.data.fromAmount + '')
          && (this.data.toAmount + '')
          && (this.$S2N(this.data.toAmount) >= this.$S2N(this.data.fromAmount))
          && (this.FromPrice + '')
          && (this.ToPrice + ''))
    },
    filteredMarket() {
      return this.coins.filter((e) =>
          this.$coinUnit[e.destinationCoin].toLowerCase().includes(this.marketSearch.toLowerCase())
          || this.$coinLabel[e.destinationCoin].toLowerCase().includes(this.marketSearch.toLowerCase())
      )
    },
    FromPrice() {
      return this.fromPercent ? this.data.fromPricePercent : this.data.fromPriceAmount
    },
    ToPrice() {
      return this.toPercent ? this.data.toPricePercent : this.data.toPriceAmount
    },
    statusLabel,
    statusVariant
  },
  methods: {
    fixAmount() {
      this.data.fromAmount = this.$A2N(this.data.fromAmount || 0).toFixed(this.data.decimal)
      this.data.toAmount = this.$A2N(this.data.toAmount || 0).toFixed(this.data.decimal)
    },
    fixBase() {
      this.data.fromPriceAmount = this.$A2N(this.data.fromPriceAmount || 0).toFixed(this.data.precision).toLocaleString('en')
      this.data.toPriceAmount = this.$A2N(this.data.toPriceAmount || 0).toFixed(this.data.precision).toLocaleString('en')
    },
    setMarketType() {
      this.data.marketType = `${this.marketType.from}_${this.marketType.to}`
    },
    async getMarkets() {
      const [res,] = await this.$http.get('/markets?isActive=true')
      if (res) {
        this.coins = res.data.content.filter(i => i.sourceCoin === 'TOMAN')
      }
    },
    resetData() {
      this.data = {
        orderType: 'BUY',
        doOrder: false,
        stopInMax: false,
        doBetterPrice: false,
        fromAmount: '',
        toAmount: '',
        decimal: 8,
        precision: 0,
        max: 0,
        exp: 0,
        period: 60,
        ...this.job,
        fromPricePercent: Math.abs(this.job.fromPricePercent || 0) || '',
        toPricePercent: Math.abs(this.job.toPricePercent || 0) || '',
        fromPriceAmount: Math.abs(this.job.fromPriceAmount || 0) || '',
        toPriceAmount: Math.abs(this.job.toPriceAmount || 0) || '',
      }
      this.fromNegative = (this.job.fromPricePercent || this.job.fromPriceAmount) < 0
      this.toNegative = (this.job.toPricePercent || this.job.toPriceAmount) < 0
      this.fromNegative = !(this.job.fromPricePercent || this.job.fromPriceAmount) ? true : this.fromNegative
      this.toNegative = !(this.job.toPricePercent || this.job.toPriceAmount) ? true : this.toNegative
    },
    async sendData() {
      if (this.loading && !this.enabled) return
      this.loading = true
      const data = {
        ...this.data,
        fromAmount: this.$S2N(this.data.fromAmount),
        toAmount: this.$S2N(this.data.toAmount),
        fromPricePercent: this.fromPercent ? (this.$S2N(this.data.fromPricePercent) * (this.fromNegative ? -1 : 1) * 0.01) : undefined,
        fromPriceAmount: this.fromPercent ? undefined : (this.$S2N(this.data.fromPriceAmount) * (this.fromNegative ? -1 : 1)),
        toPricePercent: this.toPercent ? (this.$S2N(this.data.toPricePercent) * (this.toNegative ? -1 : 1) * 0.01) : undefined,
        toPriceAmount: this.toPercent ? undefined : (this.$S2N(this.data.toPriceAmount) * (this.toNegative ? -1 : 1)),
      }
      const [res,] = await this.$http[this.data.id ? 'put' : 'post'](this.$botLink + '/api/v01/job', data)
      if (res) {
        this.$emit('submitted')
        const text = this.data.id ? 'اصلاح' : 'ایجاد'
        this.$error('', `ربات با موفقیت ${text} شد.`, 'success')
      }
      this.loading = false
    },
    async getData() {
      this.state.loading = true

      const params = {
        size: 100,
        page: 1,
        userAccountLevel: 'SUPERVISOR',
      }
      const res = await this.$axios('/users', {params})
      this.supervisors = res.data.content
    },
    async updateUser(e) {
      if (e) {
        this.spinner = true
        const token = await this.getToken(e.id)
        if (token) {
          this.data.name = e.email
          this.data.token = token
        } else {
          this.data.name = ''
        }
        this.spinner = false
      } else {
        this.job = {}
        this.resetData()
        this.setMarketType()
      }
    },
    async getToken(e) {
      const [res,] = await this.$http.get(`/users/unlimited-token/v1/${e}`)
      if (res) {
        return `Bearer ${res?.data?.token}`
      } else {
        return false
      }
    }
  },
  mounted() {
    if (this.data.marketType) {
      [this.marketType.from, this.marketType.to] = [this.$M2RC(this.data.marketType), this.$M2SC(this.data.marketType)]
    } else {
      this.setMarketType()
    }
    this.resetData()
    this.getMarkets()
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="scss">

</style>